import styled from 'styled-components';
import { WHITE } from '../../../../utils/colours';


export const OptionsBox = styled.div`
    position:absolute;
    top: 54px;
    background-color: ${WHITE(15)};
    color: ${WHITE()};
    border-radius: 4px;
    font-weight: 100;
    width: 245px;
    font-size: 13px;
    overflow: auto;
    max-height: 170px;
    z-index: 2;
`;

export const Option = styled.div`
    cursor: pointer;
    padding: 10px;
    &:hover { 
        background-color: ${WHITE(15)};
    }
`;

export const OptionsArrow = styled.div`
    margin-left: -20px;
    margin-top: 5px;
    cursor: pointer;
`;