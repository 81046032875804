import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { alphabeticalOrderComparison } from '../../utils/string';
import { doesStringMatchSearchTerm } from '../../utils/string';

import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

import translationKeys from '../../translations/keys';

import Span from '../span';
import { Checkbox as CheckboxInput } from '../form';
import SearchBox from '../search-box';
import { Flex } from '../layout';
import Spinner from '../spinner';
import SearchableText from '../searchable-text';
import ImageLoader from '../image-loader';
import {
    OptionsListContainer,
    OptionContainer,
    OptionButton,
    OptionCheckboxLabel,
    ListContainer,
    GroupHeader,
    GroupExpandButton,
    GroupsContainer,
    OptionActionButton,
    SpinnerContainer,
    BottomButton,
    OptionCount,
    BulkActionButton
} from './OptionsList.styles';



/** Renders list of options for user to choose from. User can be allowed to select only one or multiple options. Options can be grouped. */
const OptionsList = memo(
    ({
        type = 'select',
        options,
        groups,
        onGroupExpanded,
        onGroupSelected,
        max,
        onChange,
        onClearAll,
        fullHeight = false,
        maxListHeight,
        bottomButton,
        alwaysShowGroup = false,
        loadOptionImage,
        showSelectAll,
        showSearch = true,
        t,
        ...props
    }) => {
        // Show "Select all" button when specified for `type` 'multiselect'
        const selectAll = type === 'multiselect' && showSelectAll;

        // Search term
        const [searchTerm, setSearchTerm] = useState('');

        // Show search box if there are more than six options
        // Hide though if "Select all" button is showing as there is limited space
        const search = showSearch && options.length > 6;

        // Need to monitor number of selected items if `type` is 'multiselect` and `max` has been specified
        const numberOfSelectedOptions = type === 'multiselect' && max && options.filter(({ selected }) => selected).length;
        const maxMultiselectLimitReached = numberOfSelectedOptions && (max - numberOfSelectedOptions) <= 0;

        // If using groups, this identifies whether we should show the group headers
        // This will be true if not all options are in the same group (i.e. multiple groups) or `alwaysShowGroup` is true
        // To identify multiple groups, we cannot just check groups.length instead as groups may contain more groups than are actually used
        const showGroups = groups && (alwaysShowGroup || (options.length > 1 && options.slice(1).some(({ groupId }) => groupId !== options[0].groupId)));

        const showTopBar = (search || (type === 'multiselect' && onClearAll && options.length > 0));

        return (
            <OptionsListContainer $fullHeight={fullHeight} {...props}>

                {
                    showTopBar && (
                        <Flex
                            row
                            alignItems="centre"
                            justifyContent="space-between"
                            style={{
                                marginBottom: 10,
                                width: '100%',

                                // Hopefully this is never needed as a scrollbar here would be unpleasant
                                overflow: 'auto'
                            }}
                        >
                            {/* Search box */}
                            {search && (
                                <SearchBox
                                    value={searchTerm}
                                    onChange={setSearchTerm}
                                    style={onClearAll ? { maxWidth: '60%' } : { }}
                                    variant="dark"
                                />
                            )}
                            {
                                // Only used if type is 'multiselect'
                                type === 'multiselect' && onClearAll && options.length > 0 && (
                                    <BulkActionButton
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                        disabled={options.every(({ selected }) => !selected)}
                                        onClick={onClearAll}
                                    >
                                        {t(translationKeys.forms.CLEAR)}
                                    </BulkActionButton>
                                )
                            }

                            {/* Button to select all checkboxes 
                                This isn't currently used and will have to be moved if we're keeping clear at the top instead?
                            */}
                            {/* {
                                selectAll && (
                                    <BulkActionButton
                                        disabled={options.every(({ selected }) => selected)}
                                        onClick={() => {
                                            // Pass all unselected keys as true to onChange
                                            const changes = options.reduce((acc, { key, selected }) => selected ? acc : { ...acc, [key]: true }, {});
                                            onChange(changes);
                                        }}
                                    >
                                        {t(translationKeys.filters.SELECT_ALL)}
                                    </BulkActionButton>
                                )
                            } */}
                        </Flex>
                    )
                }

                

                {/* List of options */}
                {
                    // If groups have been provided (and there are multiple groups) then render in groups
                    // Note we just use normal List component if there is only one group
                    showGroups ? (
                        <GroupsContainer style={maxListHeight ? { maxHeight: maxListHeight } : undefined}>
                            {
                                _.chain(options)
                                    // Filter out options not matching search term
                                    // Include group name in comparison
                                    // Also remove any option that doesn't have a group (this should never happen but including it as a safety net)
                                    .filter(({ label, groupId, key }) => {

                                        if (!groups[groupId]) {
                                            return false;
                                        }

                                        return doesStringMatchSearchTerm(groups[groupId].name, searchTerm) || doesStringMatchSearchTerm(label, searchTerm);
                                    })
                                    // Group options
                                    .groupBy('groupId')
                                    // Convert to array for sorting
                                    .map((options, groupId) => ({ groupName: groups[groupId].name, options, groupId: groups[groupId].id ?? groupId }))
                                    // Sort groups into alphabetical order
                                    .sort(({ groupName: a }, { groupName: b }) => alphabeticalOrderComparison(a,b))
                                    // Render Group component for each group
                                    .map(({ options, groupName, groupId }) => (
                                        <Group
                                            key={groupId}
                                            id={groupId}
                                            name={groupName}
                                            loading={groups[groupId].loading}
                                            options={options}
                                            loadOptionImage={loadOptionImage}
                                            type={type}
                                            max={max}
                                            numberOfSelectedOptions={numberOfSelectedOptions}
                                            onChange={onChange}
                                            searchTerm={searchTerm}
                                            onExpanded={onGroupExpanded ? () => onGroupExpanded(groupId) : undefined}
                                            onSelected={onGroupSelected ? () => onGroupSelected(groupId) : undefined}
                                        />
                                    ))
                                    .value()
                            }
                        </GroupsContainer>
                    // We do not render group header if there is only one group, but still must follow `loading` property
                    // So if only one group but that group is loading, just render spinner
                    ) : groups && groups[options[0]?.groupId]?.loading ? (
                        <SpinnerContainer><Spinner /></SpinnerContainer>
                    // Just render usual list if no groups or one non-loading group
                    ) : options.length > 0 ? (
                        <List
                            type={type}
                            maxListHeight={maxListHeight}
                            options={options
                                .filter(({ label }) =>
                                    doesStringMatchSearchTerm(label, searchTerm)
                                )}
                            showFirstBorder={search || selectAll}
                            maxMultiselectLimitReached={maxMultiselectLimitReached}
                            onChange={onChange}
                            searchTerm={searchTerm}
                            loadOptionImage={loadOptionImage}
                        />
                    ) : null
                }
                

                
                {/* "Add new option" button */}
                {
                    bottomButton?.onClick && bottomButton.text && (
                        <BottomButton
                            onClick={bottomButton.onClick}
                        >
                            {bottomButton.text}
                        </BottomButton>
                    )
                }
            </OptionsListContainer>
        );
    },
    _.isEqual
);

// Group includes header and List
const Group = memo(
    ({ id, name, options, type, max, numberOfSelectedOptions, onChange, searchTerm, onExpanded, onSelected, loading, loadOptionImage }) => {

        const [expanded, setExpanded] = useState(false);

        const maxMultiselectLimitReached = type === 'multiselect' && max && numberOfSelectedOptions >= max;

        return (
            <>
                <GroupHeader>
                    {
                        // Show loading spinner if user wants this group expanded but it is still loading
                        // Otherwise just show toggle button
                        expanded && loading ? (
                            <Spinner style={{ margin: '0 7px' }}/>
                        ) : (
                            <GroupExpandButton
                                type="button"
                                onClick={() => {
                                    if (!expanded && onExpanded) {
                                        onExpanded();
                                    }

                                    setExpanded(expanded => !expanded);
                                }}
                                $expanded={expanded}
                                aria-expanded={expanded}

                                // id attribute of List
                                aria-controls={id}
                            />
                        )
                    }
                    
                    <Flex
                        alignItems="centre"
                        columnGap={20}
                        rowGap={5}
                        wrap
                        style={{
                            maxWidth: '100%',
                            overflow: 'hidden'
                        }}
                    >
                        <SearchableText
                            bold
                            text={name}
                            searchString={searchTerm}
                        />
                        {
                            // Show count
                            type === 'multiselect' && (
                                <Span
                                    light
                                    fontSize="80%"
                                >
                                    ({options.reduce((acc, { selected }) => selected ? acc + 1 : acc, 0)}/{options.length})
                                </Span>
                            )
                        }
                    </Flex>

                    <Checkbox
                        selected={options.every(({ selected }) => selected)}
                        onChange={newSelected => {

                            const callOnChangeWithOptions = (options, selected) => {
                                onChange(options.reduce((acc, { key }) => ({ ...acc, [key]: selected }), {}));
                            }

                            // If checkbox has been unticked, deselect all options
                            if (!newSelected) {
                                callOnChangeWithOptions(options, false);
                                return;
                            }

                            // Otherwise checkbox has been ticked so select as many options as possible

                            // Invoke callback if provided
                            if (onSelected) {
                                onSelected();
                            }

                            // If there is not enough capacity to select all, select as many as allowed
                            if (type === 'multiselect' && max) {
                                const remainingCapacity = max - numberOfSelectedOptions;
                                const optionsToSelect = options.filter(({ selected }) => !selected);
                                if (optionsToSelect.length > remainingCapacity) {
                                    // Select first n unselected options where n is remaining capacity
                                    callOnChangeWithOptions(optionsToSelect.slice(0,remainingCapacity), true);
                                    return;
                                }
                            }

                            // Otherwise we can select all
                            callOnChangeWithOptions(options, true);
                            return;
                        }}
                        maxMultiselectLimitReached={maxMultiselectLimitReached}
                        style={{ marginLeft: 8 }}
                        $noMTop
                    />
                </GroupHeader>
                {
                    expanded && !loading && (
                        <List
                            // id used by aria-controls on GroupExpandButton
                            id={id}

                            // Don't show the list if group is collapsed or loading
                            style={expanded && !loading ? undefined : { display: 'none' }}

                            type={type}
                            options={options}
                            maxMultiselectLimitReached={maxMultiselectLimitReached}
                            onChange={onChange}
                            searchTerm={searchTerm}
                            loadOptionImage={loadOptionImage}
                        />
                    )
                }       
            </>
        );
    },
    _.isEqual
);

// List of options
const List = ({ maxListHeight, options, showFirstBorder, type, maxMultiselectLimitReached, onChange, searchTerm, loadOptionImage }) => {
    return (
        <ListContainer
            style={{
                ...(maxListHeight ? { maxHeight: maxListHeight } : {})
            }}
        >
            {
                // Filter options by search term
                options
                    .map((option, idx) => (
                        <Option
                            {...option}
                            showFirstBorder={( showFirstBorder && idx === 0 )|| idx !== 0}
                            type={type}
                            maxMultiselectLimitReached={maxMultiselectLimitReached}
                            onChange={onChange}
                            searchTerm={searchTerm}
                            optionKey={option.key}
                            loadOptionImage={loadOptionImage}
                        />
                    ))
            }
        </ListContainer>
    );
}

// Individual option
const Option = memo(
    ({ label, optionKey, selected, description, image, action, disabled, count, showFirstBorder, type, maxMultiselectLimitReached, onChange, searchTerm, loadOptionImage }) => {

        const { accentColour } = useWhiteLabelComponent();

        return (
            <OptionContainer
                $firstBorder={showFirstBorder}
                $disabled={
                    (type === 'multiselect' && !selected && maxMultiselectLimitReached) ||
                    (type === 'select' && disabled)
                }
            >
                {
                    // Render buttons for type 'select' and checkboxes for type 'multiselect'
                    type === 'select' ? (
                        <>
                            <OptionButton
                                onClick={() => {
                                    onChange(optionKey);
                                }}
                                disabled={disabled}
                            >
                                <SearchableText
                                    block
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                    text={label}
                                    searchString={searchTerm}
                                />
                                {
                                    selected && (
                                        <Span colour={accentColour} style={{ marginLeft: 10 }}>
                                            &#10003;
                                        </Span>
                                    )
                                }
                            </OptionButton>
                            {
                                action?.onClick && action.text && (
                                    <OptionActionButton
                                        onClick={action.onClick}
                                    >
                                        {action.text}
                                    </OptionActionButton>
                                )
                            }
                        </>
                    ) : (
                        <OptionCheckboxLabel $disabled={!selected && maxMultiselectLimitReached}>
                            {image ? (
                                <Flex
                                    alignItems="centre"
                                    gap={10}
                                    style={{
                                        overflow: 'hidden'
                                    }}
                                >
                                    <ImageLoader
                                        width={100}
                                        height={56.25}
                                        src={image !== 'loading' && image !== 'error' ? image : undefined}
                                        alt={label}
                                        error={image === 'error'}
                                        enlargeOnHover
                                        loadImage={() => loadOptionImage(optionKey)}
                                    />
                                    <MultiselectText label={label} searchTerm={searchTerm} count={count} description={description} />
                                </Flex>
                            ) : (
                                <MultiselectText label={label} searchTerm={searchTerm} count={count} description={description} />
                            )}
                            <Checkbox
                                selected={selected}
                                onChange={newSelected => onChange({ [optionKey]: newSelected })}
                                maxMultiselectLimitReached={maxMultiselectLimitReached}
                                style={{ marginLeft: 5 }}
                                name={label + ` ${count}`}
                                $noMTop
                            />
                        </OptionCheckboxLabel>
                    )
                }
            </OptionContainer>
        );
    }
);

// Checkbox component used for multiselect
const Checkbox = ({ selected, onChange, maxMultiselectLimitReached, ...props }) => {
    return (
        <CheckboxInput
            type="checkbox"
            value={selected}
            onChange={(event) => onChange(event.target
                        .checked)
            }
            disabled={!selected && maxMultiselectLimitReached}
            {...props}
            variant="dark"
        />
    );
}

const MultiselectText = ({ label, searchTerm, count, description }) => (
    <Flex
        column
        gap={5}
        style={{ overflow: 'hidden' }}
    >
        <Span
            style={{
                display: 'inline-flex',
                alignItems: 'center'
            }}
        >
            <SearchableText text={label} searchString={searchTerm} />
            {
                count !== undefined && (
                    <OptionCount
                        // 20px is not wide enough for 3+ digits
                        // Needs a min-width so it holds its width when text is too long for label
                        style={{ minWidth: count > 99 ? 'min-content' : 20 }}
                    >{count}</OptionCount>
                )
            }
        </Span>
        {description}
    </Flex>
);

OptionsList.propTypes = {
    /** Whether users select one or multiple options in the list. */
    type: PropTypes.oneOf(['select', 'multiselect']),
    /** Function called when user interacts with an option. If `type` if 'select', key of clicked option will be passed to function. If `type` is 'multiselect', an object will be passed to function with key(s) of option interacted with as key and true/false as value. */
    onChange: PropTypes.func,
    /** Function called to clear all options. If this prop is used, a 'Clear all' button is shown at top of list. Ignored if `type` is not `multiselect`. */
    onClearAll: PropTypes.func,
    /** List of options. */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            /** Text label for option. */
            label: PropTypes.string.isRequired,
            /** Unique identifier for option. */
            key: PropTypes.any.isRequired,
            /** Whether option is selected or not. Required if `type` is 'multiselect'. If not specified and `type` is `select`, the first option will be taken to be the selected one. */
            selected: PropTypes.bool,
            /** Either URL of image to show for option, 'loading' if loading image, or 'error' if failed to load image. Currently only supported if `type` is 'multiselect'. Only supports 16:9 images. */
            image: PropTypes.string,
            /** If all properties are provided, a button is shown next to the option which can be clicked to perform some action. Only supported if `type` is 'select'. */
            action: PropTypes.shape({
                /** Function called when button clicked. Gets passed option `key`. */
                onClick: PropTypes.func.isRequired,
                /** Button text. */
                text: PropTypes.string.isRequired
            }),
            /** Id of group to which this option belongs. Required if using groups. */
            groupId: PropTypes.any,
            /** Option is disabled. Only supported if `type` is 'select'. */
            disabled: PropTypes.bool,
            /** Number put in brackets after label. Used by filters to represent the number of items matching this filter option. Currently only supported by `type` 'multiselect'. */
            count: PropTypes.number,
            /** Node rendered beneath label. Currently only supported if `type` is 'multiselect'. */
            description: PropTypes.node
        })
    ),
    /** Show a "Select all" button. Only valid for type "multiselect". The search box will not show if this is true. */
    showSelectAll: PropTypes.bool,
    /** Invoked with option key as argument when option mounts. Only used if `image` property included in option config. */
    loadOptionImage: PropTypes.func,
    /** Options can be divided into groups. This prop must be an object with an entry for each group. The keys must be group ids. When this prop is provided, each option must have a `groupId`. If only one group is specified, the group header will not be displayed. */
    groups: PropTypes.objectOf(PropTypes.shape({
        /** Name of group. */
        name: PropTypes.string.isRequired,
        /** Unqiue id of group. Use this if id is not a string. */
        id: PropTypes.any,
        /** Whether additional information needs to be loaded before group can be expanded (e.g. fetch option images). If a user tries to expand a group whilst this is true, a loading spinner is shown. */
        loading: PropTypes.bool
    })),
    /** Default behaviour is for group header not to be shown if all options belong to the same group. Set this prop to true to always show group header. */
    alwaysShowGroup: PropTypes.bool,
    /** Callback function that is invoked when a group is expanded. Gets passed group id. */
    onGroupExpanded: PropTypes.func,
    /** Callback function that is invoked when a group is selected. Gets passed group id. */
    onGroupSelected: PropTypes.func,
    /** Component fills height of parent. Use when you want OptionsList to be a set height. */
    fullHeight: PropTypes.bool,
    /** Maximum height of list (excludes search box and 'Clear all' button). Use when you want OptionsList to be its natural height up to a maximum. Can be number of pixels or any valid CSS (e.g. "60vh"). */
    maxListHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Config for button to render below list. Button is only rendered if all properties are provided. */
    bottomButton: PropTypes.shape({
        /** Function called when button clicked. */
        onClick: PropTypes.func.isRequired,
        /** Text for button. */
        text: PropTypes.string.isRequired
    }),
    /** Max number of options that can be selected. Only applies if `type` is 'multiselect'. */
    max: PropTypes.number
};

export default withTranslation()(OptionsList);
