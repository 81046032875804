import styled from 'styled-components';

const border = `1px solid #3a3a3a`;
const background = '#000';

export const TooltipContainer = styled.div`
    background: ${background};
    color: #fff;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    z-index: 600;
    font-weight: 100;
    border-radius: 6px;
    padding: 5px 10px;

    border: ${border};
    box-shadow: 0 0 20px 0 #6b6b6b26;

    /* ${({ $maxHeight }) =>
        typeof $maxHeight === 'number'
            ? `max-height:${$maxHeight}px;overflow-y:auto;`
            : ''} */
`;

TooltipContainer.displayName = 'TooltipContainer';


export const Arrow = styled.div`
    position: absolute;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;

    ${({ $placement, size }) => {
        if ($placement?.match(/top/)) {
            return `bottom: -${size / 2}px;`;
        } else if ($placement?.match(/right/)) {
            return `left: -${size / 2}px;`;
        } else if ($placement?.match(/left/)) {
            return `right: -${size / 2}px;`;
        } else {
            // Bottom
            return `top: -${size / 2}px;`;
        }
    }}

    &:after {
        content: " ";
        background-color: ${background};
        position: absolute;
        transform: rotate(45deg);
        width: ${({ size }) => size}px;
        height: ${({ size }) => size}px;

        ${({ $placement }) => {
            switch($placement) {
                case 'bottom':
                default:
                    return `border-left: ${border}; border-top: ${border};`;
                case 'top':
                    return `border-right: ${border}; border-bottom: ${border};`;
                case 'right':
                    return `border-right: ${border}; border-top: ${border};`;
                case 'left':
                    return `border-left: ${border}; border-bottom: ${border};`;
            }
        }}

        box-shadow: ${({ $placement }) => {
            switch($placement) {
                case 'bottom':
                default:
                    return '-1px -1px 1px rgba(0, 0, 0, 0.1)';
                case 'top':
                    return '1px 1px 1px rgba(0, 0, 0, 0.1)';
                case 'right':
                    return '-1px 1px 1px rgba(0, 0, 0, 0.1)';
                case 'left':
                    return '1px -1px 1px rgba(0, 0, 0, 0.1)';
            }
        }};
    }
`;

Arrow.displayName = 'Arrow';