import styled from 'styled-components';
import { NavLink as RRNavLink } from 'react-router-dom';
import Link from '../link';

import { WHITE, VL_BLACK } from '../../utils/colours';

// In px
const sidebarPadding = 16;
const mobileSidebarPadding = 16;
const mobileHeaderHeight = 30;
const collapsedLogoHorizontalMargin = 20;

// Transition times in seconds
const collapseDuration = 0.4;
const collapseFadeDuration = 0.2;
const collapseFadeDelay = 0.22;


// Relative container fills AppLayout grid cell
const SIDEBAR_WIDTH_COLLAPSED = 88; // In px
const SIDEBAR_WIDTH_DESKTOP = 250; // In px
export const SidebarRelativeContainer = styled.div`
    position: relative;
    width: ${({ $collapsed }) =>
        $collapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH_DESKTOP}px;
    transition: width ${collapseDuration}s;
    height: 100vh;

    @media only screen and (max-width: ${({ $mobileBreakpoint }) =>
            $mobileBreakpoint}px) {
        height: auto;
        width: 100%;
        transition: none;
    }
`;

SidebarRelativeContainer.displayName = 'SidebarRelativeContainer';

// Absolute container fills parent SidebarRelativeContainer on desktop and when menu is not open on mobile (and actually has relative positioning in both these cases)
// When mobile menu opened, it switches to absolute positioning and fills screen
export const SidebarAbsoluteContainer = styled.nav`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ $backgroundColour }) => $backgroundColour};

    display: flex;
    flex-direction: column;
    padding: 15px ${sidebarPadding}px;
    overflow-x: hidden;
    overflow-y: auto;

    @media only screen and (max-width: ${({ $mobileBreakpoint }) =>
        $mobileBreakpoint}px) {
        height: ${({ $mobileMenuOpen }) =>
            $mobileMenuOpen ? '100vh' : 'auto'};
        padding: ${mobileSidebarPadding}px;
        z-index: 15;
        position: ${({ $mobileMenuOpen }) =>
            $mobileMenuOpen ? 'absolute' : 'relative'};
    }
`;

SidebarAbsoluteContainer.displayName = 'SidebarContainer';

export const Header = styled.header`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    ${({ $collapsed, $noCollapsedLogo }) =>
        $collapsed && $noCollapsedLogo
            ? `
        margin: 0 -${sidebarPadding - collapsedLogoHorizontalMargin}px;
    `
            : ''}

    @media only screen and (max-width: ${({ $mobileBreakpoint }) =>
        $mobileBreakpoint}px) {
        justify-content: space-between;
        height: ${mobileHeaderHeight}px;
        min-height: ${mobileHeaderHeight}px;
        margin: 0;
        overflow: hidden;
    }
`;

Header.displayName = 'Header';

// Cannot use maxWidth and maxHeight props in Logo component as need different values on mobile to desktop
export const LogoContainer = styled.div`
    max-width: 202px;
    max-height: 135px;
    display: flex;
    justify-content: center;
    min-width: 100%;
    opacity: ${({ $collapsed }) => ($collapsed ? 0 : 1)};
    transition: opacity ${collapseFadeDuration}s;
    ${({ $collapsed }) =>
        $collapsed ? `` : `transition-delay: ${collapseFadeDelay}s;`}

    ${({ $collapsed, $noCollapsedLogo }) =>
        $collapsed && $noCollapsedLogo
            ? `
        width: 100%;
        max-width: 100%;
        opacity: 1;
    `
            : ''}

    @media only screen and (max-width: ${({ $mobileBreakpoint }) =>
        $mobileBreakpoint}px) {
        opacity: 1;
        max-width: 150px;
        max-height: ${mobileHeaderHeight}px;
        min-width: 0;
        transition: none;
        width: auto;
    }
`;

LogoContainer.displayName = 'LogoContainer';

export const CollapsedLogoContainer = styled.div`
    position: absolute;
    width: ${SIDEBAR_WIDTH_COLLAPSED - 2 * collapsedLogoHorizontalMargin}px;
    height: 100%;
    max-width: ${SIDEBAR_WIDTH_COLLAPSED - 2 * collapsedLogoHorizontalMargin}px;
    max-height: 100%;
    min-height: ${SIDEBAR_WIDTH_COLLAPSED - 2 * collapsedLogoHorizontalMargin}px;
    top: 0;
    left: ${collapsedLogoHorizontalMargin - sidebarPadding}px;
    opacity: ${({ $collapsed }) => ($collapsed ? 1 : 0)};
    transition: opacity ${collapseFadeDuration}s;

    display: flex;
    justify-content: center;
    align-items: center;

    a {
        /* Not entirely sure why this is needed by SVG logos don't appear otherwise */
        width: 100%;
    }

    ${({ $collapsed }) =>
        $collapsed ? `transition-delay: ${collapseFadeDelay}s;` : ''}

    @media only screen and (max-width: ${({ $mobileBreakpoint }) =>
        $mobileBreakpoint}px) {
        opacity: 0;
        transition: none;
    }
`;

CollapsedLogoContainer.displayName = 'CollapsedLogoContainer';

// Hidden on mobile until hamburger icon clicked
export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;

    ${({ $mobileBreakpoint, $mobileMenuOpen }) =>
        !$mobileMenuOpen &&
        `
        @media only screen and (max-width: ${$mobileBreakpoint}px) {
            display: none;
        }
    `}
`;

Menu.displayName = 'Menu';

export const NavLinks = styled.ul`
    margin-left: -${sidebarPadding}px;
    margin-right: -${sidebarPadding}px;
    padding: 0;
`;

NavLinks.displayName = 'NavLinks';

export const MainNavLinks = styled(NavLinks)`
    margin-top: 40px;
    flex-grow: 1;
`;

MainNavLinks.displayName = 'MainNavLinks';

export const NavItem = styled.li`
    list-style-type: none;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: ${({ $mobileBreakpoint }) => `${$mobileBreakpoint}px`}) {
        height: 40px;
    }
`;

NavItem.displayName = 'NavItem';

export const MainNavItem = styled(NavItem)`
    width: 100%;
`;

MainNavItem.displayName = 'MainNavItem';

// Common styles for NavLinks and NavButtons
const styles = (textColour, alternativeTextColour, mobileBreakpoint, collapsed, backgroundColour, accentColour, accentColour2) => `
    text-decoration: none;
    font-size: 12px;
    font-weight: 100;
    color: ${textColour};

    height: 100%;
    background: none;
    border: none;
    padding: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: ${mobileBreakpoint}px) {
        font-size: 16px;
        flex-direction: row;
        justify-content: flex-start;
        span {
            margin-left: 10px;
        }
    }

    span {
        transition: opacity ${collapseFadeDuration}s;
        transition-delay: ${collapseFadeDelay}s;
    }

    :hover {
        cursor: pointer;
        background: ${accentColour2 ? accentColour2 : WHITE(50)};
        ${accentColour2 ? '' :  `color: ${VL_BLACK()}`};
    }
    @media (pointer: coarse) {
        :hover {
            background: none;
            color: ${textColour};
        }
    }

    ${BetaTag} {
        background: ${accentColour ? accentColour : WHITE(75)};
        color: ${backgroundColour}
    }

    &.selected {
        color: ${alternativeTextColour};
        background: ${accentColour ? accentColour : WHITE(75)};

        ${BetaTag} {
            background: ${textColour};
            color: ${accentColour === WHITE(25) ? backgroundColour : accentColour};
        }
    }

    @media (hover: hover) {
        :hover {

            ${BetaTag} {
                background: ${accentColour};
            }
        }
    }

    ${
        collapsed
            ? `
        span {
            opacity: 0;
            transition-delay: 0s;
        }

        @media only screen and (max-width: ${mobileBreakpoint}px) {
            span {
                transition: none;
                opacity: 1;
            }
        }
    `
            : ''
    }
`;

export const NavLink = styled(RRNavLink).attrs({ activeClassName: 'selected' })`
    ${({ $textColour, $alternativeTextColour, $mobileBreakpoint, $collapsed, $backgroundColour, $accentColour, $accentColour2 }) =>
        styles($textColour, $alternativeTextColour, $mobileBreakpoint, $collapsed, $backgroundColour, $accentColour, $accentColour2)}
    padding: 12px 5px 12px 5px;
    @media only screen and (max-width: ${({ $mobileBreakpoint }) => `${$mobileBreakpoint}px`}) {
        padding: ${sidebarPadding}px;
    }
`;

NavLink.displayName = 'NavLink';

export const NavButton = styled.button`
    ${({ $textColour, $alternativeTextColour, $mobileBreakpoint, $collapsed }) =>
        styles($textColour, $alternativeTextColour, $mobileBreakpoint, $collapsed)}
    padding: 12px 5px 12px 5px;
    @media only screen and (max-width: ${({ $mobileBreakpoint }) => `${$mobileBreakpoint}px`}) {
        padding: 0 ${sidebarPadding}px;
    }
`;

NavButton.displayName = 'NavButton';

export const IconContainer = styled.div`
    height: 20px;
    width: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 3px 0;

    svg {
        max-width: 20px;
        max-height: 20px;
        margin: auto;
        display: block;

        /* Safari needs this. */
        flex-grow: 1;
    }
`;

IconContainer.displayName = 'IconContainer';

export const MainNavLink = styled(NavLink)`
    width: 100%;

    &.selected {
        background: ${({ $accentColour }) => $accentColour};
    }
`;

MainNavLink.displayName = 'MainNavLink';

export const BetaTag = styled.span`
    margin-left: auto;
    font-size: 80%;
    border-radius: 50px;
    padding: 3px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    height: 22px;
`;

BetaTag.displayName = 'BetaTag';

export const Divider = styled.hr`
    border-color: ${({ $colour }) => $colour};
    width: 100%;
    margin: 50px 0 0;
`;

Divider.displayName = 'Divider';

export const Footer = styled.footer`
    color: ${({ $colour }) => $colour};
    font-weight: 100;
    text-align: center;
    font-size: 75%;
    margin-top: 20px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    min-width: 100%;

    transition: opacity ${collapseFadeDuration}s ${collapseFadeDelay}s;
    ${({ $collapsed }) =>
        $collapsed ? `opacity: 0; transition-delay: 0s;` : ''}
    ${({ $mobileBreakpoint }) =>
        `
        @media only screen and (max-width: ${$mobileBreakpoint}px) {
            opacity: 1;
        }
    `}
`;

Footer.displayName = 'Footer';

export const Copyright = styled.small`
    font-size: 100%;
`;

Copyright.displayName = 'Copyright';

export const FooterLink = styled(Link)`
    color: inherit !important;
`;

FooterLink.displayName = 'FooterLink';

export const MobileMenuToggle = styled.button`
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: ${Math.min(mobileHeaderHeight, 26)}px;
    height: ${Math.min(mobileHeaderHeight, 26)}px;
    align-items: center;

    svg {
        width: 100%;

        /* Override SVG defs class fill of black. */
        rect {
            fill: ${({ $colour }) => $colour ?? WHITE()};
        }
    }

    @media only screen and (max-width: ${({ $mobileBreakpoint }) =>
            $mobileBreakpoint}px) {
        display: inline-flex;
    }
`;

MobileMenuToggle.displayName = 'MobileMenuToggle';


export const CollapseButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    width: 22px;
    height: 19px;
    cursor: pointer;
    transition: transform ${collapseDuration}s;
    margin-bottom: 20px;
    color: ${({ $colour }) => $colour};

    ${({ $collapsed }) => ($collapsed ? 'transform: rotateZ(180deg);' : '')}

    svg {
        width: 100%;
        height: auto;
    }

    @media only screen and (max-width: ${({ $mobileBreakpoint }) =>
            $mobileBreakpoint}px) {
        display: none;
    }
`;

CollapseButton.displayName = 'CollapseButton';





/*
    As explained in Sidebar.jsx, this is code for old camera group selector UI.
    Leaving it here in case it comes in useful in future.
*/

// const cameraGroupSelectorBorders = `
//     border-top: 1px solid #ffffff5c;
//     border-bottom: 1px solid #ffffff5c;
//     transition: all 0.3s;
//     outline: none;
//     padding: 0 ${sidebarPadding}px;

//     :focus-visible {
//         border-color: #fff;
//     }

//     @media (hover: hover) {
//         :hover {
//             border-color: #fff;
//         }
//     }
// `;

// export const CameraGroupSelector = styled.div`
//     height: 50px;
//     margin: 30px -${sidebarPadding}px 0 -${sidebarPadding}px;

//     font-weight: 100;

//     /* Needs to be relative for popup menu */
//     position: relative;

//     @media only screen and (max-width: ${({ $mobileBreakpoint }) =>
//             $mobileBreakpoint}px) {
//         ${cameraGroupSelectorBorders}
//     }
// `;

// CameraGroupSelector.displayName = 'CameraGroupSelector';


// export const CameraGroupSelectorButton = styled.button`
//     border: none;
//     color: ${({ $colour }) => $colour};
//     cursor: pointer;
//     background: none;
//     font-weight: inherit;
//     width: 100%;
//     height: 100%;
//     text-align: left;
//     display: flex;
//     align-items: center;
//     ${cameraGroupSelectorBorders}
    
//     ::after {
//         content: '';
//         display: block;
//         width: 4px;
//         height: 4px;
//         border-left: 1px solid ${({ $colour }) => $colour};
//         border-bottom: 1px solid ${({ $colour }) => $colour};
//         transform-origin: center center;
//         transform: rotate(-45deg);
//         margin-bottom: 3px;
//         margin-left: 10px;
//         transition: opacity ${collapseFadeDuration}s;
//         transition-delay: ${collapseFadeDelay}s;

//         @media only screen and (max-width: ${({ $mobileBreakpoint }) => $mobileBreakpoint}px) {
//             margin-left: auto;
//         }

//         ${({ $collapsed, $mobileBreakpoint }) =>
//             $collapsed ? `
//                 opacity: 0;
//                 transition-delay: 0s;

//                 @media only screen and (max-width: ${$mobileBreakpoint}px) {
//                     transition: none;
//                     opacity: 1;
//                 }
//             ` : ''
//         }
//     }
// `;

// CameraGroupSelectorButton.displayName = 'CameraGroupSelectorButton';


// export const SelectedCameraGroupText = styled.div`
//     display: flex;
//     flex-direction: column;
//     max-width: 70%;

//     transition: opacity ${collapseFadeDuration}s;
//     transition-delay: ${collapseFadeDelay}s;

//     span {
//         max-width: 100%;

//         text-overflow: ellipsis;
//         white-space: nowrap;
//         overflow: hidden;
//     }

//     ${({ $collapsed, $mobileBreakpoint }) =>
//         $collapsed
//             ? `
//                 opacity: 0;
//                 transition-delay: 0s;

//                 @media only screen and (max-width: ${$mobileBreakpoint}px) {
//                     transition: none;
//                     opacity: 1;
//                 }
//             `
//             : ''
//     }
// `;

// SelectedCameraGroupText.displayName = 'SelectedCameraGroupText';



