import styled from 'styled-components';

import { WHITE } from '../../../utils/colours';

import { hideSpinButtons, inputStyling } from '../helpers';

export const InputContainer = styled.input`

    /* Styles apply to all input fields except checkbox and slider */
    :not([type='checkbox']):not([type='range']) {
        ${(props) => inputStyling(props)}

        &:read-only {
            background-color: ${WHITE(15)};
            color: ${WHITE()};
            border: none;
            opacity: 0.4;

            ::placeholder {
                color: ${WHITE()};
            }
        }

        ${({ $hideSpinButtons }) => ($hideSpinButtons ? hideSpinButtons : '')}
    }

    /* Checkbox styles. */
    &[type="checkbox"] {
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }
    }

    /* Slider styles. */
    &[type="range"] {
        width: 100%;
        background: none;
        height: 27px;
        -webkit-appearance: none;
        &::-webkit-slider-runnable-track {
            height: 2px;
            cursor: pointer;
            animate: 0.2s;
            background: ${WHITE()};
            border-radius: 4px;
            border: none;
        }
        &::-webkit-slider-thumb {
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
            border: none;
            height: 20px;
            width: 20px;
            border-radius: 20px;
            background: ${({ $accentColour }) => $accentColour};
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -9px;
        }
        &:focus::-webkit-slider-runnable-track {
            background: ${WHITE()};
        }

        &::-moz-range-track {
            height: 2px;
            cursor: pointer;
            animate: 0.2s;
            background: ${WHITE()};
            border-radius: 4px;
            border: 0px solid #010101;
        }
        &::-moz-range-thumb {
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
            border: 0px solid ${({ $accentColour }) => $accentColour};
            height: 20px;
            width: 20px;
            border-radius: 20px;
            background: ${({ $accentColour }) => $accentColour};
            cursor: pointer;
        }

        &::-ms-track {
            width: 100%;
            height: 2px;
            cursor: pointer;
            animate: 0.2s;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }
        &::-ms-fill-lower {
            background: ${WHITE()};
            border: 0px solid #010101;
            border-radius: 8px;
        }
        &::-ms-fill-upper {
            background: ${WHITE()};
            border: 0px solid #010101;
            border-radius: 8px;
        }
        &::-ms-thumb {
            margin-top: 1px;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
            border: 0px solid ${({ $accentColour }) => $accentColour};
            height: 20px;
            width: 20px;
            border-radius: 20px;
            background: ${({ $accentColour }) => $accentColour};
            cursor: pointer;
        }
        &:focus::-ms-fill-lower {
            background: ${WHITE()};
        }
        &:focus::-ms-fill-upper {
            background: ${WHITE()};
        }
    }
`;

InputContainer.displayName = 'InputContainer';

export default InputContainer;
